<template>
  <b-card style="min-width: 16.5rem">
    <b-card-title>
      {{ lift }}
      <b-button
          v-b-tooltip.hover
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal:elevator-video-modal
          class="btn-icon position-absolute"
          size="sm"
          style="right: 1rem; top: 1.5rem; z-index: 3; padding: 0.2rem"
          variant="secondary"
          @click="$emit('video-play', lift_video_url)"
      >
        <feather-icon icon="YoutubeIcon"/>
      </b-button>
    </b-card-title>
    <b-card-text>
      <b-row>
        <b-col class="text-center" cols="6">
          <b-badge
              class="p-75 w-100 text-truncate"
              style="font-size: 1rem"
              variant="light-success"
          >
            {{
              lift_position_param
                  ? lift_position_param["LiftMode"]
                  : "N/A"
            }}
          </b-badge>
          <label>运行状态</label>
        </b-col>

        <b-col class="text-center" cols="6">
          <b-badge
              class="p-75"
              style="font-size: 1rem"
              variant="light-warning"
          >
            {{
              lift_position_param
                  ? lift_position_param["CarLoad"]
                  : "N/A"
            }}
          </b-badge
          >
          <br/>
          <label>载客率</label>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-center" cols="6">
          <b-badge
              class="p-75"
              style="font-size: 1rem"
              variant="light-primary"
          >
            {{
              lift_position_list[
                  lift_position_param["ActualPosition"]
                  ]
            }}
          </b-badge
          >
          <br/>
          <label>停靠楼层</label>
        </b-col>

        <b-col class="text-center" cols="6">
          <b-badge
              class="p-75"
              style="font-size: 1rem"
              variant="light-secondary"
          >
            {{
              lift_position_list[
                  lift_position_param["AdvancePosition"]
                  ]
            }}
          </b-badge
          >
          <br/>
          <label>目标楼层</label>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-card
              bg-variant="light-secondary"
              class="text-center mb-1 pt-50"
              no-body
              style="height: 5rem"
          >
              <span class="mt-50">
                  <b-icon-caret-up-fill
                      v-if="
                          lift_position_param['MovingDirection'] === 1
                      "
                      scale="1.5"
                      variant="primary"
                  />
                  <b-icon-caret-down-fill
                      v-else-if="
                          lift_position_param['MovingDirection'] === 0
                      "
                      scale="1.5"
                      variant="primary"
                  />
                  <b-icon-stop-fill
                      v-else
                      scale="1.5"
                      variant="primary"
                  />
              </span>

            <label>运行方向</label>
          </b-card>
        </b-col>

        <b-col cols="6">
          <b-card
              bg-variant="light-secondary"
              class="mb-1"
              no-body
              style="height: 5rem"
          >
            <div
                class="d-flex justify-content-between"
                style="height: 5rem"
            >
              <div
                  :class="
                                    lift_position_param['DoorState'] &&
                                    lift_position_param['DoorState'][
                                        'Side1'
                                    ] === 0
                                        ? 's'
                                        : 'l'
                                "
                  style="
                                    background: #1890ff;
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                    height: 100%;
                                "
              ></div>
              <div
                  :class="
                                    lift_position_param['DoorState'] &&
                                    lift_position_param['DoorState'][
                                        'Side1'
                                    ] === 0
                                        ? 's'
                                        : 'l'
                                "
                  style="
                                    background: #1890ff;
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                    height: 100%;
                                "
              ></div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-tabs>
        <b-tab title="楼层">
          <b-card bg-variant="light-secondary" class="mb-0">
            <vue-slider
                v-if="lift_position_param['ActualPosition']"
                v-model="
                                lift_position_param['ActualPosition'].toString()
                            "
                :data="lift_position_list"
                :disabled="true"
                :height="360"
                :process="false"
                :tooltip="'always'"
                class="vue-slide-primary"
                direction="btt"
                style="margin: 0 auto"
            ></vue-slider>
          </b-card>
        </b-tab>

        <b-tab title="环境">
          <b-card bg-variant="light-secondary" class="mb-0">
            <b-card-text>
              <div
                  class="d-flex justify-content-between mb-50"
                  v-for="param in lift_environment_param"
                  :key="param.meter_code"
              >
                                <span class="text-bold text-uppercase">{{
                                    param.param_name.split("-")[1]
                                  }}</span>
                <span>
                                    <span
                                        :style="
                                            param.cur_value === null
                                                ? 'opacity:0.5'
                                                : ''
                                        "
                                    >{{
                                        param.cur_value === null
                                            ? "N/A"
                                            : param.cur_value
                                      }}</span
                                    >
                                    <small>&nbsp;{{ param.unit }}</small>
                                </span>
              </div>
            </b-card-text>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card-text>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import store from "@/store";

export default {
  name: "elevator_item_info_card",
  props: ["lift_name"],
  directives: {
    Ripple,
  },
  components: {
    VueSlider,
  },
  setup(props) {
    const lift_position_list = {
      15: "F12",
      14: "F11",
      13: "F10",
      12: "F9",
      11: "F8",
      10: "F7",
      9: "F6",
      8: "F5",
      7: "F3",
      6: "F2",
      5: "F1",
      4: "B1",
      3: "B2",
      2: "B3",
      1: "B5",
    };
    const lift_position_param = ref({});
    const lift_environment_param = ref({});
    const lift_video_url = ref("");
    const lift = computed(() => {
      return props["lift_name"];
    });

    const update_params = () => {
      let position_param = {};
      let environment_param = [];
      if (lift.value) {
        for (const lift_item of store.state.jsf35.equip_list) {
          if (lift_item["equip_name"] === props["lift_name"]) {
            lift_item.params.map((param) => {
              if (
                  param.meter_code &&
                  param.meter_code.indexOf("JSF_HJJC_CGQ_LIFT") >
                  -1
              ) {
                environment_param.push(param);
              }
            });
            lift_video_url.value = lift_item["ip_address"];
            break;
          }
        }

        position_param =
            store.state.jsf35.equip_list_current_data[lift.value];
      }
      lift_position_param.value = position_param;
      lift_environment_param.value = environment_param;

      // console.log("position_param", position_param);
    };

    watch(
        () => lift,
        () => {
          update_params();
        }
    );

    watch(
        () => store.state.jsf35.equip_list_current_data[lift.value],
        () => {
          update_params();
        }
    );

    onMounted(() => {
      update_params();
    });

    return {
      lift,
      lift_position_list,
      lift_position_param,
      lift_environment_param,
      lift_video_url,
    };
  },
};
</script>

<style lang="scss" scoped>
.l {
  width: 48%;
  animation: long 300ms linear;
}

.s {
  width: 15%;
  animation: short 300ms linear;
}

@keyframes long {
  from {
    width: 15%;
  }

  to {
    width: 48%;
  }
}

@keyframes short {
  from {
    width: 48%;
  }

  to {
    width: 15%;
  }
}
</style>